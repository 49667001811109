import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import typingSound from "../../assets/sound/keypress.mp3";
import { KeyFill, Person, Rocket, RocketTakeoff } from "react-bootstrap-icons";
import { checkTokenExpiry } from "../../common/utils";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import mailImg from "../../assets/img/mail.png";

function ChangePass() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
    checkTokenExpiry();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const audio = new Audio(typingSound);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    audio.play();
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
    audio.play();
  };

  const changePassword = async (e) => {
    e.preventDefault();

    if (password.trim() !== repeatPassword.trim()) {
      notifyError("Password doesn't matched");
      return;
    }
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/changepassword";

    let requestModal = {
      password: password,
      repeatPassword: repeatPassword,
      username: userInfo.username,
      otp: localStorage.getItem("otp"),
    };

    try {
      const response = await axios
        .post(url, requestModal, {
          headers: { "x-api-key": apiKey },
        })
        .then((response) => {
          let res = response.data;
          setLoading(false);
          notifySuccess(res.message);
          localStorage.clear();

          setTimeout(() => {
            navigate("/launch");
          }, 2000);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };
  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper mt-4">
            <div className="auth-card">
              <img
                className="m-auto d-block"
                height={60}
                width={60}
                src={mailImg}
                alt="mail"
              />
              <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                Change Password
              </h3>
              <form onSubmit={changePassword}>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    required=""
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <KeyFill className="icon" />
                </div>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Repeat Password"
                    required=""
                    value={repeatPassword}
                    onChange={handleRepeatPasswordChange}
                  />
                  <KeyFill className="icon" />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="gradient-btn w-100"
                    data="Update"
                  ></button>
                </div>
              </form>
            </div>
          </section>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );
}
export default ChangePass;
