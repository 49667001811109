import React, { useState, useEffect } from 'react';

const OTPTimer = ({ expirationTime, onExpire }) => {
    const [timeRemaining, setTimeRemaining] = useState(expirationTime);

    useEffect(() => {
        const timer = setInterval(() => {
            if (timeRemaining > 0) {
                setTimeRemaining(timeRemaining - 1);
            } else {
                clearInterval(timer); // Stop the timer
                onExpire(); // Callback function to handle OTP expiration
            }
        }, 1000); // Update every 1 second

        return () => {
            clearInterval(timer); // Clean up the timer when the component unmounts
        };
    }, [timeRemaining, onExpire]);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    return (
        <span className="fs-6 text-cyan time-font">
            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
    );
};

export default OTPTimer;