import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import typingSound from "../../assets/sound/keypress.mp3";

import giftImage from "../../assets/img/gift.png";
import crownImage from "../../assets/img/premium.png";
import animationData from "../../components/lottie/animation.json";

import Lottie from "lottie-react";
import DashboardLayout from "../../components/layout/dashoboard-layout";

const countryCodes = require("country-codes-list");
const myCountryCodesObject = countryCodes.customList(
  "countryCallingCode",
  "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
);

const options = Object.entries(myCountryCodesObject).map(([code, label]) => ({
  value: code,
  label: label,
}));

const customStyles = {
  control: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#6b6b6b",
    boxShadow: "none",
    fontSize: "0.87rem",
    padding: "0 1.125rem",
    border: "1px solid #00ffff5c !important",
    height: "52px",
    borderRadius: "7px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.87rem",
    background: state.isSelected ? "gray" : "#002424",
    color: state.isSelected ? "black" : "white",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#6b6b6b",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6b6b6b",
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    color: "#6b6b6b",
  }),
};

const notifySuccess = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

function Setting() {
  const navigate = useNavigate();
  const audio = new Audio(typingSound);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [existingUsername, setexistingUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountry] = useState("");
  const [countryCodeIndex, setCountryCodeIndex] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [aboveAge18, setAboveAge18] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
    audio.play();
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    audio.play();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    audio.play();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    audio.play();
  };

  const handleCountryChange = (e) => {
    setCountry("+" + e.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    audio.play();
  };

  const handleAboveAge18Change = (e) => {
    if (e.target.checked) {
      setAboveAge18("1");
    } else {
      setAboveAge18("0");
    }
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const getUser = async () => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = `https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/user?username=${userInfo.username}`;

    try {
      const response = await axios
        .get(url, { headers: { "x-api-key": apiKey } })
        .then((response) => {
          let res = response.data.user;
          if (res) {
            setName(res.name);
            setUsername(res.username);
            setexistingUsername(res.username);
            setEmail(res.email);
            setCountry(res.countryCode);
            setPhone(res.phoneNumber);
            setAboveAge18(res.aboveAge18);
            setLoading(false);
            let index = options.findIndex(
              (x) => x.value === res.phoneCode.split("+")[1]
            );
            setCountryCodeIndex(index);
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const updateUser = async () => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/user";

    const requestModal = {
      name,
      username,
      existingUsername,
      email,
      countryCode,
      phoneNumber,
      aboveAge18,
    };

    try {
      const response = await axios
        .post(url, requestModal, {
          headers: { "x-api-key": apiKey },
        })
        .then((response) => {
          let res = response.data.user;
          if (res) {
            notifySuccess(res.data.message);
            getUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
    getUser();
  }, []);

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <section className="page-wrapper">
            <div className="d-flex flex-column position-relative h-100">
              <div className="container mt-auto">
                <div className="row align-items-center">
                  <div className="col-xl-6 mb-5 mb-lg-0 mt-4 mt-xl-0 mx-auto">
                    <div className="auth-card">
                      <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                        Edit Account Information
                      </h3>
                      <form onSubmit={updateUser}>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
                          <div className="col mb-3">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Your name"
                              value={name}
                              onChange={handleNameChange}
                              required
                            />
                          </div>
                          <div className="col mb-3">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Username"
                              value={username}
                              onChange={handleUsernameChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1">
                          <div className="col-12 mb-3">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email address"
                              value={email}
                              onChange={handleEmailChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
                          <div className="col mb-3">
                            <Select
                              options={options}
                              isSearchable={true}
                              styles={customStyles}
                              value={options.value}
                              defaultValue={options[countryCodeIndex]}
                              onChange={handleCountryChange}
                            />
                          </div>
                          <div className="col mb-3">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Phone"
                              value={phoneNumber}
                              onChange={handlePhoneChange}
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="age"
                              checked={aboveAge18}
                              onChange={handleAboveAge18Change}
                            />
                            <label
                              className="form-check-label ms-1 small"
                              htmlFor="age"
                            >
                              Are you above 18 years of age?
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="terms"
                              checked={termsChecked}
                              onChange={handleTermsChange}
                            />
                            <label
                              className="form-check-label ms-1 small"
                              htmlFor="terms"
                            >
                              I agree to
                              <Link
                                target="_blank"
                                to="https://opic3d.com/terms-and-conditions/"
                                className="ms-1"
                              >
                                Terms &amp; Conditions
                              </Link>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            type="submit"
                            className={`gradient-btn ${
                              termsChecked || formDisabled ? "" : "disabled"
                            }`}
                            disabled={!termsChecked && !formDisabled}
                            data="Update Profile"
                          ></button>
                        </div>
                        <div className="d-flex d-md-none py-4 justify-content-center">
                          <Link to="/launch" className="nav-link text-white">
                            Already have an account? Launch
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default Setting;
