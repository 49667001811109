import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import typingSound from "../../assets/sound/keypress.mp3";

import giftImage from "../../assets/img/gift.png";
import crownImage from "../../assets/img/premium.png";
import animationData from "../../components/lottie/animation.json";
import { ArrowRightShort } from "react-bootstrap-icons";

import Lottie from "lottie-react";

const countryCodes = require("country-codes-list");
const myCountryCodesObject = countryCodes.customList(
  "countryCallingCode",
  "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
);

const options = Object.entries(myCountryCodesObject).map(([code, label]) => ({
  value: code + "-" + label.split("] ")[0].substring(1),
  label: label,
}));

const customStyles = {
  control: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#b4b4b4",
    boxShadow: "none",
    fontSize: "0.87rem",
    padding: "0 1.125rem",
    border: "1px solid #00ffff5c !important",
    height: "52px",
    borderRadius: "7px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.87rem",
    background: state.isSelected ? "gray" : "#002424",
    color: state.isSelected ? "black" : "white",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#b4b4b4",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#b4b4b4",
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    color: "#b4b4b4",
  }),
};

const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const signup = async (userData) => {
  const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
  const url =
    "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/signup";

  try {
    const response = await axios
      .post(url, userData, {
        headers: {
          "x-api-key": apiKey,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

function Signup() {
  const navigate = useNavigate();
  const audio = new Audio(typingSound);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountry] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [aboveAge18, setAboveAge18] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      username,
      email,
      password,
      countryCode,
      phoneCode,
      phoneNumber,
      aboveAge18,
      userType: 1,
    };

    if (formData.aboveAge18 == true) {
      formData.aboveAge18 = "1";
    } else {
      formData.aboveAge18 = "0";
    }

    try {
      setLoading(true);
      setFormDisabled(true);
      const response = await signup(formData);
      navigate("/confirm-mail");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 400) {
        notifyError(error.response.data.message);
      }
      setFormDisabled(false);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    audio.play();
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    audio.play();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    audio.play();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    audio.play();
  };

  const handleCountryChange = (e) => {
    setPhoneCode("+" + e.value.split("-")[0]);
    setCountry(e.value.split("-")[1]);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    audio.play();
  };

  const handleAboveAge18Change = (e) => {
    setAboveAge18(e.target.checked);
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper">
            <div className="row align-items-center">
              <div className="col-xl-5 mb-5 mb-lg-0 order-1 order-xl-0">
                <h4 className="mb-4 mt-4 mt-xl-0 text-center text-cyan">
                  Now it's easier to make a choice
                </h4>
                <div className="my-5 row align-items-center">
                  <div className="col-md-9 order-1 order-md-0 text-gray">
                    <ul className="list-unstyled mb-0 text-gray">
                      <li className="d-flex mb-2">
                        <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                        All users will be rewarded with 100 free OPIC spatial
                        miles everyday to experience 10 minutes of free 3D live
                        streaming. Unused daily miles do not carry over to the
                        next day.
                      </li>
                      <li>
                        <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                        Upgrade to premium any time.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 text-center mb-3 mb-md-0 text-center">
                    <img src={giftImage} alt="Gift" />
                    <p className="my-0 text-cyan fst-italic">Freemium</p>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-3 text-center mb-3 mb-lg-0">
                    <img src={crownImage} alt="Crown" />
                    <p className="my-0 text-cyan fst-italic">Premium</p>
                  </div>
                  <div className="col-md-9">
                    <ul className="list-unstyled mb-0 text-gray">
                      <li className="d-flex mb-2">
                        <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                        Enjoy All Freemium Perks
                      </li>
                      <li className="d-flex mb-2">
                        <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                        Produce immersive 3D content for upto 5 live audience
                      </li>
                      <li className="d-flex mb-2">
                        <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                        Gain 200 spatial miles for every 10 users you refer
                      </li>
                      <li className="d-flex mb-2">
                        <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                        Exclusive Local recording - Coming soon
                      </li>
                      <li className="d-flex mb-2">
                        <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                        Stream from professional stereo 3D cameras - Coming soon
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 mb-5 mb-lg-0 mt-4 mt-xl-0 offset-xl-2 signup">
                <div className="auth-card">
                  <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                    Sign up to OPIC
                  </h3>
                  <form onSubmit={handleFormSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Your name"
                          value={name}
                          onChange={handleNameChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Username"
                          value={username}
                          onChange={handleUsernameChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email address"
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3 theme-select">
                        <Select
                          options={options}
                          isSearchable={true}
                          styles={customStyles}
                          onChange={handleCountryChange}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone"
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="age"
                        checked={aboveAge18}
                        onChange={handleAboveAge18Change}
                      />
                      <label
                        className="form-check-label text-gray"
                        htmlFor="age"
                      >
                        Are you above 18 years of age?
                      </label>
                    </div>
                    <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="terms"
                        checked={termsChecked}
                        onChange={handleTermsChange}
                      />
                      <label
                        className="form-check-label text-gray"
                        htmlFor="terms"
                      >
                        I agree to
                        <Link
                          target="_blank"
                          to="https://opic3d.com/terms-and-conditions/"
                          className="text-decoration-none text-cyan"
                        >
                          &nbsp;Terms &amp; Conditions
                        </Link>
                      </label>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className={`gradient-btn w-100 ${
                          termsChecked || formDisabled ? "" : "disabled"
                        }`}
                        disabled={!termsChecked && !formDisabled}
                        data="Sign Up"
                      ></button>
                    </div>
                    <div className="d-flex mt-3 justify-content-center text-gray small">
                      Already have an account?
                      <Link to="/launch" className="nav-link text-cyan">
                        &nbsp;Launch
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );
}

export default Signup;
