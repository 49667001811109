import React, { useEffect, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";

import typingSound from "../../assets/sound/keypress.mp3";
import forgetIcon from "../../assets/img/password-reset.png";

import { KeyFill, Person, Rocket, RocketTakeoff } from "react-bootstrap-icons";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import rocketIcon from "../../assets/icons/icon-rocket-launch.png";

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
    const getQueryParamValue = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const paramValue = urlParams.get('callback');
      return paramValue;
    };

    // Get the query parameter value
    const paramValue = getQueryParamValue();

    if (paramValue) {
      // If the query parameter exists, save it in localStorage
      localStorage.setItem('callback', paramValue);
      setQueryParamValue(paramValue);
    }

  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [queryParamValue, setQueryParamValue] = useState('');
  const [loading, setLoading] = useState(true);

  const audio = new Audio(typingSound);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    audio.play();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    audio.play();
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaValid(true);
  };

  const handleRecaptchaExpired = (value) => {
    setRecaptchaValid(false);
  };

  const handleRecaptchaError = (value) => {
    setRecaptchaValid(false);
  };

  const launch = async (e) => {
    e.preventDefault();

    const userData = {
      username,
      password,
      recaptchaValue,
    };

    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/launch";

    try {
      const response = await axios
        .post(url, userData, {
          headers: {
            "x-api-key": apiKey,
          }
        })
        .then((response) => {
          let res = response.data;
          localStorage.setItem("token", res.token);
          localStorage.setItem("user", JSON.stringify(res.user));
          navigate("/otp");
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
          
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper mt-4">
            <div className="auth-card m-auto">
              <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                Launch
              </h3>
              <form onSubmit={launch}>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Username or Email"
                    required=""
                    value={username}
                    onChange={handleUsernameChange}
                  />
                  <Person className="icon" />
                </div>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    required=""
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <KeyFill className="icon" />
                </div>
                <div className="d-flex justify-content-center position-relative mb-3">
                  <ReCAPTCHA
                    sitekey="6LeezmgjAAAAAHEncIGgf9kIsBbC2yY9QiqzVg84"
                    onChange={handleRecaptchaChange}
                    onErrored={handleRecaptchaError}
                    onExpired={handleRecaptchaExpired}
                    theme={"dark"}
                  />
                </div>
                <div className="pb-4 d-flex justify-content-between align-items-center small text-gray">
                  <Link
                    to="/forget-pass"
                    className="nav-link d-flex align-items-center gap-2"
                  >
                    <img width={20} src={forgetIcon} alt="forget" />
                    Forgot Password?
                  </Link>
                  <span className="d-flex align-items-center gap-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember"
                      checked={rememberMe}
                      onChange={handleRememberMe}
                    />
                    <label className="form-check-label" htmlFor="remember">
                      Remember Me?
                    </label>
                  </span>
                </div>
                <div className="d-flex flex-wrap gap-3 justify-content-center mb-3 mt-5 position-relative">
                  <div className="icon-rocket border position-absolute z-2 bg-black p-2 rounded-circle">
                    {/* <img src={rocketIcon} /> */}
                    <RocketTakeoff className="fs-5 text-cyan"/>
                  </div>
                  <button
                    className={`gradient-btn w-100 " ${
                      !recaptchaValid ? "disabled" : ""
                    }`}
                    data="Launch Now"
                    disabled={!recaptchaValid}
                  ></button>
                </div>
                <div className="d-flex justify-content-center text-gray small">
                  Don't have an account?
                  <Link to="/signup" className="nav-link text-cyan">
                    <span className="">&nbsp;Signup</span>
                  </Link>
                </div>
              </form>
            </div>
          </section>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );
}

export default Login;
