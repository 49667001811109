import React, { useEffect, useState } from "react";
import "./assets/scss/theme.scss";
import Login from "./pages/auth/login";
import animationData from "./components/lottie/animation.json";
import Lottie from "lottie-react";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => {
      setLoading(false); // Set loading to false when data is fetched
    }, 1000);
  }, []);

  return (
    <div className="app">
      <div>
        {loading ? (
          <div className="lottie-wrapper page-wrapper">
            <Lottie animationData={animationData} loop={true} />
          </div>
        ) : (
          <Login />
        )}
      </div>
    </div>
  );
}

export default App;
