import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import typingSound from "../../assets/sound/keypress.mp3";
import animationData from "../../components/lottie/animation.json";
import { checkTokenExpiry } from "../../common/utils";
import Lottie from "lottie-react";
import mailImg from "../../assets/img/mail.png";
import OtpInput from "react-otp-input";
import OTPTimer from "../../common/otpTimer";
import { _limits, _paymentStatus } from "../../common/constants";

function OTP() {

  useEffect(() => {
    checkTokenExpiry();
  }, []);

  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [expirationTime, setExpirationTime] = useState(_limits.otpExpiryMinutes * 60);

  const audio = new Audio(typingSound);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleOtpExpiration = () => {
    setOtpExpired(true);
  };

  const handleOtp = (otp) => {
    setOtp(otp);
    audio.play();
  };

  const submitOTP = async (e) => {
    e.preventDefault();

    const userData = {
      otp,
      username: userInfo.username,
    };

    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/otp";

    try {
      const response = await axios
        .post(url, userData, {
          headers: {
            "x-api-key": apiKey,
          },
        })
        .then((response) => {
          let res = response.data;
          localStorage.setItem("token", res.token);
          localStorage.setItem("user", JSON.stringify(res.user));
          let user = res.user;

          if (user.paymentStatus === _paymentStatus.newUser) {
            navigate("/choose-package");
          } else {
            navigate("/lobby");
          }

          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const resendOTP = async () => {
    const userData = { username: userInfo.username };
    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/resendotp";

    try {
      setLoading(true);
      const response = await axios
        .post(url, userData, {
          headers: { "x-api-key": apiKey }
        })
        .then((response) => {
          let res = response.data;
          setLoading(false);
          notifySuccess(res.message);
          setExpirationTime(_limits.otpExpiryMinutes * 60);
          setOtpExpired(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper mt-4">
            <div className="auth-card">
              <img
                className="m-auto d-block"
                height={60}
                width={60}
                src={mailImg}
                alt="mail"
              />
              <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                OTP Verification
              </h3>
              <p className="align-items-center d-flex justify-content-between mb-4 text-gray">
                Enter the OTP sent to {userInfo?.email}
                {!otpExpired ? (<OTPTimer className="time-font" expirationTime={expirationTime} onExpire={handleOtpExpiration} />) : ''}
              </p>
              <form onSubmit={submitOTP}>
                <div className="mb-3 opt-control">
                  <OtpInput
                    value={otp}
                    onChange={handleOtp}
                    numInputs={6}
                    inputType={"number"}
                    inputStyle={"form-control"}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    class="gradient-btn w-100"
                    data="Verify OTP"
                  ></button>
                </div>
                {otpExpired ? (
                  <p className="cursor-pointer mb-0 mt-3 small text-center text-cyan text-decoration-underline text-underline" onClick={() => resendOTP()}>
                    Resend OTP
                  </p>
                ) : ''}
              </form>
            </div>
          </section>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );
}
export default OTP;
