import React, { useEffect, useState } from "react";
import axios from "axios";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { CloudArrowUpFill, EyeFill } from "react-bootstrap-icons";
import { Trash3 } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "react";
import { Modal } from "react-bootstrap";

function PromoteFiles() {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDBFile, setSelectedDBFile] = useState(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [base64String, setBase64String] = useState("");

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // const base64 = event.target.result.split(',')[1];
        const base64 = event.target.result;
        setBase64String(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  // Clear the selected file
  const clearSelectedFile = () => {
    setBase64String("");
    setSelectedFile(null);
  };

  const getAdvertisements = async () => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = `https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/advertisements?username=${userInfo.username}`;

    try {
      const response = await axios
        .get(url, {
          headers: { "x-api-key": apiKey },
        })
        .then((response) => {
          let advertisements = response.data.advertisements;
          console.log(advertisements);
          setAdvertisements(advertisements);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const saveFile = async () => {
    if (base64String === "") notifyError("please upload file");

    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/upload";

    let requestModal = {
      username: userInfo.username,
      base64String: base64String,
    };

    try {
      const response = await axios
        .post(url, requestModal, {
          headers: { "x-api-key": apiKey },
        })
        .then((response) => {
          let res = response.data;
          notifySuccess(res.message);
          setLoading(false);
          clearSelectedFile();

          setTimeout(() => {
            getAdvertisements();
          }, 1000);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const getFile = async (s3FileKey) => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/file";

    let requestModal = {
      username: userInfo.username,
      s3FileKey: s3FileKey,
    };

    try {
      const response = await axios
        .post(url, requestModal, {
          headers: { "x-api-key": apiKey },
        })
        .then((response) => {
          let url = response.data.signedUrl;
          setLoading(false);
          window.open(url, "_blank");
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const deleteFile = async (s3bucketId, s3FileKey) => {

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/deletefile";

    let requestModal = {
      username: userInfo.username,
      s3bucketId: s3bucketId,
      s3FileKey: s3FileKey,
    };

    try {
      setdeleteShow(false);
      setLoading(true);
      const response = await axios
        .post(url, requestModal, {
          headers: { "x-api-key": apiKey }
        })
        .then((response) => {
          getAdvertisements();
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    getAdvertisements();
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setSelectedFileURL(fileURL);
    }
  }, [selectedFile]);

  useEffect(() => {
    getAdvertisements();
  }, []);


  const handleDeleteFile = (file) => {
    setSelectedDBFile(file);
    setdeleteShow(true);
  }

  const [deleteShow, setdeleteShow] = useState(false);

  return (
    <>
      <Modal
        size="sm"
        centered
        show={deleteShow}
        onHide={() => setdeleteShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-uppercase text-center h6 my-0 text-cyan">
              Delete Confirmation
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this file?</Modal.Body>
        <Modal.Footer>
          <button onClick={() => setdeleteShow(false)} className="gradient-btn py-2" data="No"></button>
          <button
            className="gradient-btn py-2"
            data="Yes"
            onClick={() =>
              deleteFile(selectedDBFile.id, selectedDBFile.s3FileName)
            }
          ></button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              <div className="row justify-content-center mb-3">
                <div className="col-lg-12 text-center">
                  <h4 className="mb-2 text-cyan">Upload Images and Videos For Advertisements</h4>
                  <div>
                    <div className="uploadFile">
                      <label for="fileUpload">
                        <CloudArrowUpFill />
                        <span> Upload images (png, jpeg) & videos (mp4, webm)</span>
                        <small>Upto 3 images and 2 videos (5MB each)</small>
                      </label>
                      <input
                        type="file"
                        id="fileUpload"
                        onChange={handleFileChange}
                        className="d-none"
                      />
                      {selectedFile && (
                        <ul className="img-preview">
                          <li>
                            <span>
                              {selectedFile.type.startsWith("image") ? (
                                <img src={selectedFileURL} alt="" />
                              ) : (
                                <video src={selectedFileURL} controls />
                              )}
                            </span>
                            {selectedFile.name}
                            <Trash3
                              className="trash_icon"
                              onClick={() => clearSelectedFile()}
                            />
                          </li>
                        </ul>
                      )}
                      {/* show after file upload */}
                      <button
                        className="gradient-btn mt-3"
                        data="Save"
                        onClick={() => saveFile()}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="card card-cyan-bg h-100">
                    <div className="card-header py-3">
                      <h6 className="mb-0">Uploaded Files</h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-dark">
                        <thead>
                          <tr>
                            <th>Filename</th>
                            <th>File Type</th>
                            <th>Size</th>
                            <th align="end" className="text-end">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {advertisements.map((advertisement) => (
                            <tr>
                              <td>{advertisement.s3FileName}</td>
                              <td>{advertisement.fileType}</td>
                              <td>
                                {(
                                  advertisement.s3FileSize /
                                  (1024 * 1024)
                                ).toFixed(2)}
                                MB
                              </td>
                              <td align="end">
                                <div className="d-flex gap-2 justify-content-end text-cyan">
                                  <EyeFill
                                    className="cursor-pointer"
                                    onClick={() =>
                                      getFile(advertisement.s3FileName)
                                    }
                                  />
                                  <Trash3
                                    className="cursor-pointer"
                                    onClick={() => handleDeleteFile(advertisement)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                          {advertisements.length === 0 ? (
                            <tr>
                              <td className="text-center" colSpan={4}>
                                {" "}
                                No Record Found{" "}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default PromoteFiles;
