import React from "react";
import AuthLayout from "../../components/layout/auth-layout";
import mailImg from "../../assets/img/mail.png";

function MailConfirm() {
  return (
    <>
      <AuthLayout>
        <section className="page-wrapper mt-4">
          <div className="auth-card card">
            <img
              className="m-auto d-block"
              height={60}
              width={60}
              src={mailImg}
              alt="mail"
            />
            <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
              Sign-Up Confirmation
            </h3>
            <p className="text-gray">Thank you for signing up with OPIC!</p>
            <h6 className="fst-italic">Check your email:</h6>
            <p className="text-gray">
              We've sent a confirmation link to the email address you provided
              during the registration process. It should arrive in your inbox
              shortly.
            </p>
            <h6 className="fst-italic">Click the confirmation link:</h6>
            <p className="text-gray">
              Open the email and click on the provided confirmation link. This
              ensures that we have the correct email address, and you're all set
              to explore OPIC3D
            </p>
          </div>
        </section>
      </AuthLayout>
    </>
  );
}

export default MailConfirm;
