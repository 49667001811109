import React from "react";
import AuthLayout from "../../components/layout/auth-layout";

function TAndC() {
  return (
    <>
      <AuthLayout>
        <div className="page-wrapper">
          <div className="container">
            <h3 className="pt-4"> Terms and conditions</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
              repellat laboriosam officia magnam ad obcaecati, reprehenderit
              nemo perferendis voluptatum corrupti, voluptas quos repellendus in
              aliquid voluptates, error unde deserunt omnis ducimus dolores!
              Nobis commodi voluptates laborum perspiciatis quaerat a hic ipsam
              doloribus iste, saepe repellendus explicabo similique temporibus
              tempore eum.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
              repellat laboriosam officia magnam ad obcaecati, reprehenderit
              nemo perferendis voluptatum corrupti, voluptas quos repellendus in
              aliquid voluptates, error unde deserunt omnis ducimus dolores!
              Nobis commodi voluptates laborum perspiciatis quaerat a hic ipsam
              doloribus iste, saepe repellendus explicabo similique temporibus
              tempore eum.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
              repellat laboriosam officia magnam ad obcaecati, reprehenderit
              nemo perferendis voluptatum corrupti, voluptas quos repellendus in
              aliquid voluptates, error unde deserunt omnis ducimus dolores!
              Nobis commodi voluptates laborum perspiciatis quaerat a hic ipsam
              doloribus iste, saepe repellendus explicabo similique temporibus
              tempore eum.
            </p>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default TAndC;
