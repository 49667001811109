import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import giftImage from "../../assets/img/gift.png";
import crownImage from "../../assets/img/premium.png";
import DashboardLayout from "../../components/layout/dashoboard-layout";

import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { ArrowRightShort } from "react-bootstrap-icons";
import { _userTypes, _stripeProducts, _paymentStatus } from "../../common/constants";

function PackagesUpgrade() {
  useEffect(() => {
    getStripeProducts();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const minQuantity = userInfo.userType === _userTypes.user ? 300 : 800;
  const [loading, setLoading] = useState(false);
  const [stripeProducts, setStripeProducts] = useState([]);
  const [quantity, setQuantity] = useState(minQuantity);

  const quantityStyle = { "min-height": "inherit", padding: "0.5rem 1rem" };

  const navigate = useNavigate();

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const checkValidation = () => {
    if (quantity < minQuantity) {
      notifyError("The minimum quantity to purchased is " + minQuantity);
      return false;
    }
    return true;
  };

  const getStripeProducts = async () => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/products";

    try {
      const response = await axios
        .get(url, { headers: { "x-api-key": apiKey } })
        .then((response) => {
          let products = response.data.products;
          if (userInfo.userType === _userTypes.user) {
            products = products.filter(
              (x) => x.active === true && x.id === _stripeProducts.user
            );
          } else if (userInfo.userType === _userTypes.advertiser) {
            products = products.filter(
              (x) => x.active === true && x.id === _stripeProducts.advertiser
            );
          }
          setStripeProducts(products);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const createCheckoutSession = async (subscription) => {
    if (!checkValidation()) return;

    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url =
      "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/checkoutsession";

    try {
      let requestModal = {
        priceId: subscription.default_price,
        product: subscription.name,
        currency: subscription.price.currency,
        username: userInfo.username,
        quantity: quantity,
        isLoggedIn: true,
      };
      const response = await axios
        .post(url, requestModal, { headers: { "x-api-key": apiKey } })
        .then((response) => {
          let res = response.data;
          window.location.href = res.data.url;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };
  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              <div className="row justify-content-center mb-5">
                <div className="col-lg-8 text-center">
                  <h1 className="mb-2 text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                    Purchase OPIC Miles
                  </h1>
                </div>
              </div>
              <div className="row g-3 g-xl-5">
                <div className="col-md-6 col-lg-6">
                  <div className={`card card-cyan-bg rounded-4 ${userInfo.paymentStatus === _paymentStatus.freeUser ? "active-plan" : ""}`}>
                    {
                        userInfo.paymentStatus === _paymentStatus.freeUser ?
                        (
                        <div className="active-badge">
                          <p className="plan-text">Active Plan</p>
                        </div>
                        ) : ""
                      }
                    <div className="card-body p-4 d-flex flex-column justify-content-between">
                      <div>
                        <h4 className="align-items-center d-flex gap-3 h6 fw-bold mb-4">
                          <img height={62} width={62} src={giftImage} />
                          Freemium
                        </h4>
                        <ul className="list-unstyled mb-5 text-gray">
                          <li className="d-flex mb-2">
                            <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                            All users will be rewarded with 100 free OPIC
                            spatial miles everyday to experience 10 minutes of
                            free 3D live streaming.
                          </li>
                          <li className="d-flex mb-2">
                            <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                            Unused daily miles do not carry over to the next
                            day.
                          </li>
                          <li className="d-flex mb-2">
                            <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                            Upgrade to premium any time.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {stripeProducts.map((product) => (
                  <div className="col-md-6 col-lg-6">
                    <div className={`card card-cyan-bg rounded-4 ${userInfo.paymentStatus === _paymentStatus.premiumUser ? "active-plan" : ""}`}>
                      {
                        userInfo.paymentStatus === _paymentStatus.premiumUser ?
                        (
                        <div className="active-badge">
                          <p className="plan-text">Active Plan</p>
                        </div>
                        ) : ""
                      }
                      <div className="card-body p-4 d-flex flex-column justify-content-between">
                        <div>
                          <h4 className="align-items-center d-flex gap-3 h6 fw-bold mb-4">
                            <img
                              height={62}
                              width={62}
                              src={product?.images[0]}
                              className="text-center"
                            />
                            {product?.name} - $
                            {(product.price?.unit_amount / 100).toFixed(2)} /
                            mile
                          </h4>
                          <ul className="list-unstyled mb-5 text-gray">
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                              Enjoy All Freemium Perks
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                              Produce immersive 3D content for upto 5 live
                              audience
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                              Gain 200 spatial miles for every 10 users you
                              refer
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                              Exclusive Local recording - Coming soon
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />{" "}
                              Stream from professional stereo 3D cameras -
                              Coming soon
                            </li>
                          </ul>
                          <div className="row mb-4">
                            <div className="col-md-7">
                              <label className="w-100 mb-1">Quantity:</label>
                              <input
                                type="number"
                                placeholder="Select Quantity"
                                className="rounded-2 w-100 quantity form-control"
                                value={quantity}
                                onChange={handleQuantityChange}
                                style={quantityStyle}
                              />
                            </div>
                            <div className="col-md-5 text-end">
                              <label className="w-100 mb-1">Total:</label>
                              <p className="fw-bold h3 mb-0 text-cyan">
                                $
                                {(
                                  (product.price?.unit_amount / 100) *
                                  quantity
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-grid">
                          <button
                            type="button"
                            class="gradient-btn"
                            data=" Buy Now"
                            onClick={() => createCheckoutSession(product)}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}
export default PackagesUpgrade;
