import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  ArrowBarLeft,
  BoxArrowLeft,
  CreditCard2FrontFill,
  GearWideConnected,
  PersonFill,
  PersonGear,
} from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import logo from "../../assets/icons/opic-logo.webp";
import settings from "../../assets/img/OPIC-user-settings.png";

function DashboardLayout({ children }) {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/launch');
  };
  return (
    <>
      <nav className="navbar navbar-absolute navbar-expand-lg navbar-transparent py-2 py-lg-4">
        <div className="container justify-content-center">
          <div className="navbar-wrapper">
            <div className="navbar-toggle d-inline">
              <button type="button" className="navbar-toggler">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <a className="navbar-brand me-0">
              <Link className="text-decoration-none text-center" to="/lobby">
                <img src={logo} alt="" />
                <p>
                  3D LiVe <span>Conversation</span> FOR <span>EVERYONE</span>
                </p>
              </Link>
            </a>
          </div>
          <ul className="navbar-nav ms-auto d-md-flex align-items-center gap-3 theme-nav">
            <li className="fs-3">
              <Dropdown data-bs-theme="dark" drop={"down-start"} align={"end"}>
                <Dropdown.Toggle className="custom-toggle p-0">
                  <img
                    height={30}
                    src={settings}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/settings">
                    <PersonGear />
                    Account
                  </Dropdown.Item>
                  <Dropdown.Item href="/upgrade-package">
                    <CreditCard2FrontFill />
                    Upgrade to Premium
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <a className="dropdown-item nolink">
                    Land the OPIC 3d Live app
                  </a>
                  <Dropdown.Item href="#" onClick={()=> logout} >
                    <BoxArrowLeft />
                    (Logout)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>

      <div className="container">
        <div className="auth-box">
          <main>{children}</main>
          <footer>
            <p>
              The logos and trademarks seen in the advertisements are registered
              copyrighted content of the respective organizations.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
