import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import Table from "react";
import dailyIcon from "../../assets/img/daily.png";
import produceIcon from "../../assets/icons/icon-produce-2.png";
import consumeIcon from "../../assets/icons/icon-consume-1.png";
import promoteIcon from "../../assets/icons/icon-AD-1.png";
import vibeIcon from "../../assets/img/confetti.png";
import nikeAD from "../../assets/img/nike.webp";
import loadingGif from "../../assets/img/Rolling-1s-200px.gif";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { checkTokenExpiry } from "../../common/utils";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { format } from "date-fns";
import { Calendar2Check, Check2All } from "react-bootstrap-icons";
import { _claimTypes } from "../../common/constants";

function Home() {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);

    checkTokenExpiry();
    checkUserClaim();
    getUserMiles();
    getUserCharges();

    let callbackURL = localStorage.getItem("callback");
    if (callbackURL) {
      setRoomId(callbackURL);
      setRoomIDJoinShow(true);
      localStorage.removeItem("callback");
    }
    
  }, []);

  const [loading, setLoading] = useState(true);
  const [spinning, setSpinning] = useState(0);
  const [claimStatus, setClaimStatus] = useState({});
  const [totalMiles, setTotalMiles] = useState(0);
  const [remMiles, setRemMiles] = useState(0);
  const [claimedMiles, setClaimedMiles] = useState(0);
  const [charges, setCharges] = useState([]);
  const [show, setShow] = useState(false);
  const [congratsShow, setCongratsShow] = useState(false);
  const [roomIDJoin, setRoomIDJoinShow] = useState(false);
  const [roomId, setRoomId] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const dailyLoginAlert = ({ closeToast }) => (
    <div className="d-flex justify-content-between claim-listing">
      <div className="d-flex align-items-center gap-3">
        <div className="claim-icon">
          <Calendar2Check />
        </div>
        <div>
          <h6 className="mb-0">Daily Login</h6>
          <span className="text-cyan">Log in Today</span>
        </div>
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className="d-flex flex-column text-end">
          <span className="text-cyan r">+100</span>
          <span>Miles</span>
        </div>
        <button
          onClick={() => {
            closeToast();
            handleShow();
          }}
          className="gradient-btn btn-sm ms-2 m-width-100"
          data="Redeem Now"
        ></button>
      </div>
    </div>
  );

  const firstLoginAlert = ({ closeToast }) => (
    <div className="d-flex justify-content-between small">
      <div className="d-flex align-items-center gap-2">
        <img height={32} src={dailyIcon} />
        <span>First Time Login</span>
      </div>
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex flex-column text-end">
          <span className="text-cyan r">+50</span>
          <span>Miles</span>
        </div>
        <button
          onClick={() => {
            closeToast();
            handleShow();
          }}
          className="gradient-btn btn-sm ms-2 m-width-100"
          data="Redeem Now"
        ></button>
      </div>
    </div>
  );

  const getUserCharges = async () => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = `https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/charge?username=${userInfo.username}`;

    try {
      const response = await axios
        .get(url, { headers: { "x-api-key": apiKey } })
        .then((response) => {
          let charges = response.data.charges;
          setCharges(charges);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const getUserMiles = async () => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = `https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/miles?username=${userInfo.username}`;

    try {
      const response = await axios
        .get(url, { headers: { "x-api-key": apiKey } })
        .then((response) => {
          let data = response.data;
          setTotalMiles(data.totalPurchasedMiles + data.totalEarnedMiles);
          setRemMiles(data.remainingMiles);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const checkUserClaim = async () => {
    setLoading(true);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = `https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/checkclaim?username=${userInfo.username}`;

    try {
      const response = await axios
        .get(url, { headers: { "x-api-key": apiKey } })
        .then((response) => {
          let claim = response.data;
          setClaimStatus(claim);

          if (!claim.firstTimeLogin) {
            toast(firstLoginAlert, {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          } else if (!claim.dailyLogin) {
            toast(dailyLoginAlert, {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const claimMiles = async (claimType) => {
    // setLoading(true);
    setSpinning(claimType);

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = `https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/claim`;

    try {
      let requestModal = {
        username: userInfo.username,
        claimType: claimType,
      };

      const response = await axios
        .post(url, requestModal, { headers: { "x-api-key": apiKey } })
        .then((response) => {
          let data = response.data.claims;

          setTotalMiles(data.totalPurchasedMiles + data.totalEarnedMiles);
          setRemMiles(data.remainingMiles);

          if (claimType === _claimTypes.firstTimeLogin) {
            setClaimedMiles(50);
          } else if (claimType === _claimTypes.dailyLogin) {
            setClaimedMiles(100);
          } else if (claimType === _claimTypes.referral) {
            setClaimedMiles(200);
          }

          handleClose();
          setCongratsShow(true);
          checkUserClaim();

          // setLoading(false);
          setSpinning(0);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setSpinning(0);
      // setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const produceStream = () => {
    window.location.href = `https://live.opic3d.com/?token=${localStorage.getItem(
      "token"
    )}`;
  };

  const promoteAds = () => {
    window.location.href = `/promote`;
  };

  const joinStream = (e) => {
    e.preventDefault();
    window.location.href = `https://live.opic3d.com/?token=${localStorage.getItem(
      "token"
    )}&roomId=${roomId}`;
  };

  const handleRoomIdChange = (e) => {
    setRoomId(e.target.value);
  };

  return (
    <>
      <ToastContainer className="header-toast" />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to OPIC</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div className="claim-listing">
            <div className="col-auto">
              <div className="d-flex align-items-center gap-3">
                <div className="claim-icon">
                  <Calendar2Check />
                </div>
                <div>
                  <h6 className="mb-0">First Time Login</h6>
                  <span className="text-danger">Exclusively yours</span>
                </div>
              </div>
            </div>
            <div className="align-items-center col-auto d-flex">
              <span className="text-cyan">+50</span>&nbsp;Miles
              {spinning === _claimTypes.firstTimeLogin ? (
                <span className="align-items-center d-inline-flex justify-content-center m-width-100 ms-2">
                  <img height={24} src={loadingGif} />
                </span>
              ) : (
                <>
                  {claimStatus.firstTimeLogin ? (
                    <>
                      <span className="m-width-100 ms-2 text-center text-cyan">
                        <Check2All className="fs-6 me-1 text-cyan" />
                        Claimed
                      </span>
                    </>
                  ) : (
                    <button
                      className={
                        "gradient-btn btn-sm ms-2 m-width-100 " +
                        (claimStatus.firstTimeLogin ? "disabled" : "")
                      }
                      data={claimStatus.firstTimeLogin ? "Claimed" : "Claim"}
                      onClick={() => claimMiles(_claimTypes.firstTimeLogin)}
                      disabled={claimStatus.firstTimeLogin ? true : false}
                    ></button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="claim-listing">
            <div className="col-auto">
              <div className="d-flex align-items-center gap-3">
                <div className="claim-icon">
                  <Calendar2Check />
                </div>
                <div>
                  <h6 className="mb-0">Daily Login</h6>
                  <span className="text-danger">Log in Today</span>
                </div>
              </div>
            </div>
            <div className="align-items-center col-auto d-flex">
              <span className="text-cyan">+100</span> Miles
              {spinning === _claimTypes.dailyLogin ? (
                <span className="align-items-center d-inline-flex justify-content-center m-width-100 ms-2">
                  <img height={24} src={loadingGif} />
                </span>
              ) : (
                <>
                  {claimStatus.dailyLogin ? (
                    <>
                      <span className="m-width-100 ms-2 text-center text-cyan">
                        <Check2All className="fs-6 me-1 text-cyan" />
                        Claimed
                      </span>
                    </>
                  ) : (
                    <button
                      className={
                        "gradient-btn btn-sm ms-2 m-width-100 " +
                        (claimStatus.dailyLogin ? "disabled" : "")
                      }
                      data="Claim"
                      onClick={() => claimMiles(_claimTypes.dailyLogin)}
                      disabled={claimStatus.dailyLogin ? true : false}
                    ></button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="claim-listing">
            <div className="col-auto">
              <div className="d-flex align-items-center gap-3">
                <div className="claim-icon">
                  <Calendar2Check />
                </div>
                <div>
                  <h6 className="mb-0">10 User Referral</h6>
                  <span className="text-danger">Refer Now!</span>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <span className="text-cyan">+200</span> Miles
              <button
                className="gradient-btn btn-sm ms-2 m-width-100"
                data="Claim"
              ></button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer> 
          <a className="text-decoration-none cursor-pointer" onClick={handleClose}>Cancel</a>
        </Modal.Footer>
      </Modal>
      {/* Congrats Modal */}
      <Modal
        size="sm"
        show={congratsShow}
        onHide={() => setCongratsShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Congrats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-3 text-center">
            <p className="mb-0 small">You just claimed +{claimedMiles} miles</p>
            <h2 className="mb-0 fw-bold">{totalMiles}</h2>
            <p className="mb-0 small">Current miles remaining</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="gradient-btn px-4 py-2"
            data="Continue"
            onClick={() => setCongratsShow(false)}
          ></button>
        </Modal.Footer>
      </Modal>
      {/*Enter Room Id */}
      <Modal
        size="sm"
        show={roomIDJoin}
        onHide={() => setRoomIDJoinShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Consume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 h-150 bg-cyan rounded mb-3">
            <img className="h-100 object-fit-contain w-100" src={consumeIcon} />
          </div>
          {/* <h6>Enter Room ID to Join</h6> */}

          {/*For joining room via opened link */}
          <h6>Room ID:</h6>
          <form onSubmit={joinStream}>
            <input
              className="form-control"
              type="text"
              placeholder="Room Id"
              value={roomId}
              onChange={handleRoomIdChange}
              required
            />
            <div className="d-flex justify-content-center">
              <button
                className="gradient-btn mt-4 px-3 py-2"
                data="Join Now"
                type="submit"
              ></button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="row">
              <div className="col-auto d-flex align-items-center">
                <h4 className="my-0 text-cyan">Lobby</h4>
              </div>
              <div className="col-auto col-xl-2 col-lg-3 ms-auto">
                {/* Button for congrats modal */}
                {/* <Button
                    data="Close"
                    onClick={() => setCongratsShow(true)}
                    className="me-2"
                  >
                    Congrats modal
                  </Button> */}
                <div className="border card h-100 card-red-bg hover-bg d-flex flex-row gap-3 justify-content-center p-2 ps-3 align-items-center small">
                  <span className="text-white">
                    Feeling <span className="text-cyan">the vibe,</span>
                    <br />
                    {userInfo.username}
                  </span>
                  <img height={50} src={vibeIcon} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-10 col-lg-9">
                <div className="row mt-4">
                  {/* Add conditon on this whole column to hide for advertiser */}
                  <div className="col-md-4">
                    <div
                      className="card card-cyan-bg hover-bg d-flex flex-row p-3 align-items-center gap-3 mb-4"
                      onClick={() => produceStream()}
                    >
                      <div className="icons-dashboard">
                        <img src={produceIcon} />
                      </div>
                      <span className="flex-grow-1 h5 mb-0">Produce</span>
                      <div className="cyan-pattern"></div>
                    </div>
                  </div>
                  {/* Add conditon on this whole column for advertiser */}
                  <div className="col-md-4">
                    <div
                      onClick={() => setRoomIDJoinShow(true)}
                      className="card card-cyan-bg hover-bg d-flex flex-row p-3 align-items-center gap-3 mb-4"
                    >
                      <div className="icons-dashboard">
                        <img src={consumeIcon} />
                      </div>
                      <span className="flex-grow-1">
                        <span className="h5 mb-0 d-block">Consume</span>
                        <span className="small">RoomID</span>
                      </span>
                      <div className="cyan-pattern"></div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="card card-cyan-bg hover-bg d-flex flex-row p-3 align-items-center gap-3 mb-4"
                      onClick={() => promoteAds()}
                    >
                      <div className="icons-dashboard">
                        <img src={promoteIcon} />
                      </div>
                      <span className="flex-grow-1 h5 mb-0">Advertise</span>
                      <div className="cyan-pattern"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8 mb-4 mb-xl-0">
                    <div className="card card-cyan-bg h-100">
                      <div className="card-header py-3">
                        <h6 className="mb-0">History of purchases</h6>
                      </div>
                      <div className="card-body">
                        <table className="table table-dark">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Product</th>
                              <th>Quantity</th>
                              <th align="end" className="text-end">
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {charges.map((charge) => (
                              <tr>
                                <td>
                                  {format(charge.created * 1000, "dd MMMM, y")}
                                </td>
                                <td>{charge.product}</td>
                                <td>{charge.quantity}x</td>
                                <td align="end">
                                  ${charge.amount_total / 100}
                                </td>
                              </tr>
                            ))}
                            {charges.length === 0 ? (
                              <tr>
                                <td className="text-center" colSpan={4}>
                                  {" "}
                                  No Record Found{" "}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="card card-cyan-bg h-100">
                      <div className="card-header py-3">
                        <h6 className="mb-0">Statistics</h6>
                      </div>
                      <div className="card-body">
                        <p className="small d-flex justify-content-between">
                          Sign up Date:{" "}
                          <span>
                            {format(userInfo.createdAt, "dd MMMM, y")}
                          </span>
                        </p>
                        <div className="d-flex justify-content-between align-items-center small py-2">
                          <Progress
                            type="circle"
                            status="default"
                            theme={{
                              default: {
                                color: "#0fbbc7",
                                trailColor: "white",
                              },
                            }}
                            strokeWidth={10}
                            width={50}
                            percent={
                              (remMiles / totalMiles) * 100 === 0
                                ? 100
                                : (remMiles / totalMiles) * 100
                            }
                          />
                          <span className="text-end">
                            Total Miles:
                            <br />
                            {totalMiles}
                          </span>
                          <span className="text-end">
                            Miles Remaining:
                            <br />
                            {totalMiles - remMiles}
                          </span>
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center small py-3">
                            <Progress
                              type="circle"
                              status="default"
                              theme={{
                                default: {
                                  Symbol: "",
                                  color: "#f56e6e",
                                  trailColor: "white",
                                },
                              }}
                              strokeWidth={10}
                              width={50}
                              percent={40}
                            />
                            <span className="text-end">
                              Remaining Purchased Miles:
                              <br />
                              50
                            </span>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3">
                <div className="mt-4">
                  <div className="hover-bg">
                    <div className="ad-box">
                      <img src={nikeAD} />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="card hover-bg">
                    <div className="ad-box">
                      <img src="https://elements-cover-images-0.imgix.net/3f4f303b-2636-4f7e-97c3-fa5f82bc0219?auto=compress%2Cformat&fit=max&w=900&s=cadccb3c7d57a36686a304af3aa00d4d" />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="card hover-bg">
                    <div className="ad-box">
                      <video
                        controls
                        src="https://opic3d.com/wp-content/uploads/2023/10/Opic-ws-1.mp4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}
export default Home;
