import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/layout/auth-layout";
import success from "../../assets/img/success.png";
import rocketIcon from "../../assets/icons/icon-rocket-launch.png";

function PaymentSuccess() {

  const navigate = useNavigate();

  const loginRoute = () => {
    navigate('/launch');
  };

  return (
    <>
      <AuthLayout>
        <section className="page-wrapper mt-4">
          <div className="container mt-auto">
            <div className="row align-items-center">
              <div className="col-xl-5 mb-5 mb-lg-0 mt-4 mt-xl-0 mx-auto">
                <div className="card card-cyan-bg dark-mode px-lg-4 py-2">
                  <div className="card-body text-center">
                    <img
                      className="ms-auto"
                      height={60}
                      width={60}
                      src={success}
                      alt="successful payment"
                    />
                    <h3 className="pt-3 pb-2 pb-lg-3">Thank you</h3>
                    <p className="pb-2 text-start">
                      Your Payment Was Successfully Processed
                    </p>
                    <p className="text-start pb-4">
                      Your transaction is now complete, and your account has
                      been updated accordingly. If you have any questions or
                      need further assistance, please don't hesitate to reach
                      out to our support team at{" "}
                      <u>support@opic3d.com</u> We're here to help you every step of the way.
                    </p>
                    <div className="d-flex justify-content-center mt-5 mb-3 position-relative">
                      <div className="icon-rocket border position-absolute z-2 bg-black p-2 rounded-circle">
                        <img src={rocketIcon} />
                      </div>
                      <button
                        className="gradient-btn"
                        onClick={() => loginRoute()}
                        data="Launch OPIC"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AuthLayout>
    </>
  );
}

export default PaymentSuccess;
