import React from "react";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Facebook, Link45deg, Messenger, Twitter } from "react-bootstrap-icons";

function ReferralFiles() {
  return (
    <>
      <DashboardLayout>
        <div className="page-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-xl-8">
                <div className="d-flex h-100 justify-content-center p-4 referred-users">
                  <div className="row">
                    <div className="col-xl-8 col-lg-10 m-auto">
                      <h3 class="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                        Refer a friend Give $10, Get $10
                      </h3>
                      <p className="pb-2 text-gray text-center">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam
                      </p>
                      <div className="refer-email">
                        <input
                          type="text"
                          className="form-control rounded-end-0"
                        />
                        <button
                          className="gradient-btn btn-sm m-width-100"
                          data="Send"
                        ></button>
                      </div>
                      <ul className="claim-listing gap-5 justify-content-center pt-0 text-center">
                        <li>
                          <span className="claim-icon m-auto mb-2">
                            <Facebook />
                          </span>
                          <span>Share</span>
                        </li>
                        <li>
                          <span className="claim-icon m-auto mb-2">
                            <Twitter />
                          </span>{" "}
                          <span>Tweet</span>
                        </li>
                        <li>
                          <span className="claim-icon m-auto mb-2">
                            <Messenger />
                          </span>{" "}
                          <span>Message</span>
                        </li>
                        <li>
                          <span className="claim-icon m-auto mb-2">
                            <Link45deg />
                          </span>{" "}
                          <span>Copy</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 mt-4 mt-lg-0">
                <div className="referred-users">
                  <h3 class="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                    Referred Users
                  </h3>
                  <ul className="referred-users-listing">
                    <li>
                      <span>
                        <img
                          src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                          alt=""
                        ></img>
                      </span>
                      Jane Cooper
                    </li>
                    <li>
                      <span>
                        <img
                          src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                          alt=""
                        ></img>
                      </span>
                      Jane Cooper
                    </li>
                    <li>
                      <span>
                        <img
                          src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                          alt=""
                        ></img>
                      </span>
                      Jane Cooper
                    </li>
                    <li>
                      <span>
                        <img
                          src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                          alt=""
                        ></img>
                      </span>
                      Jane Cooper
                    </li>
                    <li>
                      <span>
                        <img
                          src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                          alt=""
                        ></img>
                      </span>
                      Jane Cooper
                    </li>
                  </ul>
                  <div className="text-center">
                    <button
                      className="gradient-btn btn-sm m-width-100"
                      data="View More"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </DashboardLayout>
    </>
  );
}

export default ReferralFiles;
