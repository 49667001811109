const _userTypes = {
    user: 1,
    advertiser: 2,
    admin: 3
}

const _userStatus = {
    active: 1,
    inActive: 2,
    deleted: 3
}

const _paymentStatus = {
    newUser: 1,
    freeUser: 2,
    premiumUser: 3
}

const _limits = {
    otpExpiryMinutes: 2,
    contentUploadVideos: 2,
    contentUploadImages: 3,
    imageSize: 5, // IN MB
    videoSize: 20 // IN MB
}

const _claimTypes = {
    firstTimeLogin: 1,
    dailyLogin: 2,
    referral: 3
}

const _milesClaim = {
    firstTimeLogin: 50,
    dailyLogin: 100,
    referral: 200
}

const _stripeProducts = {
    user: 'prod_OolGAbpZjGQY3m',
    advertiser: 'prod_OpYea1sl6rWPPs'
}

module.exports = { _userTypes, _userStatus, _paymentStatus, _limits, _claimTypes, _milesClaim, _stripeProducts }