import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/layout/auth-layout";
import { Link } from "react-router-dom";
import mailImg from "../../assets/img/mail.png";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";


function VerifyLink() {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const token = searchParams.get("token");

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const verifyLink = async (token) => {

    const apiKey = "lSUWUf5oRd1ln67WFlFQL8ZtIEjZX2Ez5YJH6E93";
    const url = "https://7lsto4b732.execute-api.us-east-1.amazonaws.com/prod/verifylink";

    setLoading(true);

    const response = await axios.post(url, { token: token }, {
      headers: {
        "x-api-key": apiKey,
      },
    }).then(
      response => {
        notifySuccess(response.data.message);
        setLoading(false);
        setTimeout(() => {
          navigate("/launch");
        }, 1000);
      }
    ).catch(error => {
      if (error.response.status === 400) {
        notifyError(error.response.data.message);
      }
    });


  };

  useEffect(() => {
    verifyLink(token);
  }, []);

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );

}

export default VerifyLink;
